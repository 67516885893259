<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-modal
        id="modal-verify"
        ok-title="Submit"
        title="Cancel Order"
        cancel-title="cancel"
        centered
        @ok="cancel"
    >
      <b-row>
        <b-col cols="12">
          <b-alert class="p-1" show variant="warning">
            <span>By Canceling This Order, Current Booster Will Be Dismissed</span>
          </b-alert>
        </b-col>
        <b-col  cols="12">
          <b-form-group
              label="reason"
          >
            <b-form-textarea
                v-model="reason"
                rows="2"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-modal>
    <b-card v-if="order">
      <b-row>
        <b-col cols="12">
          <div class="mt-md-0  d-flex align-items-center justify-content-between">
            <h4 class="invoice-title">
              Order : <span class="invoice-number">{{ order.orderCode }}</span>
            </h4>
            <b-button
                v-b-modal.modal-verify
                class="   mb-75"
                variant="danger"
            >
              Cancel Order
            </b-button>
          </div>


        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col cols="12">
          <app-timeline>
            <app-timeline-item
                v-for="item in order.orderWarranties"
                class="transaction-item my-0"
                variant="primary"
            >
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                      rounded
                      size="42"
                      variant="light-success"
                  >
                    <feather-icon
                        icon="AtSignIcon"
                        size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div class="d-flex align-items-start ">

                    <p>
                      <b-badge
                          v-if="item.status==='Frozen'"
                          pill
                          class="p-1"
                          variant="info"
                      >
                        In Warranty
                      </b-badge>
                      <b-badge
                          v-if="item.status==='Waiting'"
                          pill
                          class="p-1"
                          variant="secondary"
                      >
                        Waiting For payment
                      </b-badge>
                      <b-badge
                          v-if="item.status==='Payed'"
                          pill
                          class="p-1"
                          variant="light-success"
                      >
                        Waiting For Boosters To Assign
                      </b-badge>
                      <b-badge
                          v-if="item.status==='Doing'"
                          pill
                          class="p-1"
                          variant="success"
                      >
                        Booster Assigned
                      </b-badge>
                      <b-badge
                          v-if="item.status==='Done'"
                          pill
                          class="p-1"
                          variant="success"
                      >
                        Booster Completed Order
                      </b-badge>
                      <b-badge
                          v-if="item.status==='Cancel'"
                          pill
                          class="p-1"
                          variant="danger"
                      >
                        Waiting For Reassign
                      </b-badge>
                    </p>
                    <span class="ml-1"
                    >{{ new Date(item.createdAt).toLocaleDateString('en-US') }}-{{ new Date(item.createdAt).toLocaleTimeString('en-US') }}</span>

                  </div>

                  <small>

                    {{ item.description }}
                  </small>
                </b-media-body>
              </b-media>
            </app-timeline-item>
          </app-timeline>

        </b-col>
      </b-row>
    </b-card>
  </b-overlay>

</template>

<script>

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
    BFormTextarea,
  BFormGroup,
  BMedia,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import OrderMediaHolder from '@/views/components/Order/OrderMediaHolder.vue'
import OrderInfoSidebar from '@/views/components/Order/OrderInfoSidebar.vue'
import Debouncer from '@/views/components/debouncer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { GetWarrantyState } from '@/libs/Api/orderWarrnties'
import { DenyOrder, OrderCancelByAdminRequest } from '@/libs/Api/Order'

export default {
  name: 'WarrantyInfo',
  components: {
    OrderMediaHolder,
    AppTimelineItem,
    BFormTextarea,
    AppTimeline,
    OrderInfoSidebar,
    Debouncer,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
    BAlert,
    BMedia,
    BAvatar,
    BBadge,
    BOverlay,
    BModal,

    vSelect,
  },
  data() {
    return {
      overlay: false,
      order: null,
      reason:''
    }
  },
  async mounted() {
    this.getWarrantyState()
  },
  methods: {
    async cancel() {
      let _this = this
      if (_this.reason) {
        _this.overlay = true
        let denyOrder = new DenyOrder(_this)
        denyOrder.setParams({
          orderCode: _this.order.orderCode,
          reason: _this.reason
        })
        await denyOrder.fetch(function (content) {
          _this.overlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.reason = ''
            _this.$router.push('/apps/orders/list')
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.overlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'reason is required',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },

    async getWarrantyState() {
      let _this = this
      _this.overlay = true

      let getWarrantyState = new GetWarrantyState(_this)
      getWarrantyState.setParams({ orderCode: _this.$route.params.id })
      await getWarrantyState.fetch(function (content) {
        _this.overlay = false
        _this.order = content.data[0]
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
  }
}
</script>

<style scoped>

</style>
